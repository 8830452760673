import { __decorate } from "tslib";
import { AuthEventType, AuthServiceType, EventbusType, LocalStorage, ModalType, ResourceActionFailed } from '@movecloser/front-core';
import { AppTheme, loadScript } from '@d24/modules/front';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { AuthAction } from '@contract/auth';
import { availableLayouts, defaultLayoutKey } from '@/shared/layouts/layouts.config';
import { CapturesError } from '@support/handle-errors';
import { DefaultLayout } from '@/shared/layouts/default';
import { Inject } from '@plugin/inversify';
import { Domain, SiteServiceType } from '@service/site';
import { ModalWrapper } from '@component/Modal/ModalWrapper';
import { Sidebar } from '@component/Sidebar';
import { AuthRepositoryType, GrantType } from '@profile/contracts';
import { SubscriptionsRepositoryType } from '@subscription/contracts';
import { SubscriptionServiceType } from '@subscription/shared/services';
import { LoginIntention } from '@profile/intentions';
import { ConnectorErrors } from '@exception/connector-errors';
import { EModal } from '@contract/modal';
import { NavigationServiceType, NavigationSlot } from '@service/navigation';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let App = class App extends Vue {
    constructor() {
        super(...arguments);
        this.appMetadata = {};
        this.adTargeting = {};
        this.wtgLoaded = false;
        this.taboolaLoaded = false;
    }
    /**
     * Determines whether any errors have recently happened.
     */
    get hasErrors() {
        return this.$errorHandler.error !== null;
    }
    /**
     * Determine if Sidebar should be rendered.
     */
    get hasLayoutASidebar() {
        if (!Object.prototype.hasOwnProperty.call(this.layoutComponent, 'hasSidebar') ||
            typeof this.layoutComponent.hasSidebar !== 'function') {
            return true;
        }
        return this.layoutComponent.hasSidebar();
    }
    /**
     * Layout that should be rendered.
     */
    get layoutComponent() {
        var _a;
        const layout = ((_a = this.$route.meta) === null || _a === void 0 ? void 0 : _a.layout) || defaultLayoutKey;
        if (!(layout in availableLayouts)) {
            return availableLayouts[defaultLayoutKey];
        }
        return availableLayouts[layout];
    }
    /**
     * Current application theme.
     */
    get theme() {
        var _a;
        if ((_a = this.me) === null || _a === void 0 ? void 0 : _a.isPremium()) {
            return AppTheme.PremiumZone;
        }
        return this.siteService.getActiveSiteTheme();
    }
    get me() {
        return this.$store.getters['profile/me'];
    }
    get isLoggedIn() {
        return this.$store.getters['profile/isUserLoggedIn'];
    }
    get isWaitingForAuth() {
        return this.$store.getters['profile/isWaitingForAuth'];
    }
    get socialLinks() {
        var _a;
        return this.navigation.hasSlot(NavigationSlot.Social)
            ? ((_a = this.navigation.getSlot(NavigationSlot.Social)) === null || _a === void 0 ? void 0 : _a.links) || [] : [];
    }
    getAppMetadata() {
        const site = this.siteService.getActiveSite();
        this.appMetadata = {
            '@context': 'https://schema.org',
            '@graph': [
                {
                    '@type': 'Organization',
                    url: site.address,
                    logo: site.logo.light
                },
                {
                    '@type': 'WebSite',
                    name: site.name,
                    url: site.address,
                    ...(this.$route.path !== '/' ? {} : {
                        potentialAction: {
                            '@type': 'SearchAction',
                            target: {
                                '@type': 'EntryPoint',
                                urlTemplate: `${site.address}search?q={search_term_string}`
                            },
                            'query-input': 'required name=search_term_string'
                        }
                    })
                },
                {
                    '@type': 'NewsMediaOrganization',
                    name: site.name,
                    logo: site.logo.light,
                    foundingDate: '',
                    url: site.address.slice(0, -1),
                    sameAs: this.socialLinks.map((link) => link.target)
                }
            ]
        };
    }
    /**
     * Listens to the events in the `Authorization` and reacts to them.
     */
    async listenToAuthService() {
        this.subscription = this.authService.listen((event) => {
            var _a;
            switch (event.type) {
                case AuthEventType.Booted: {
                    this.notify(AuthAction.Booted);
                    this.$store.dispatch('profile/setIsWaitingForAuth', false);
                    break;
                }
                case AuthEventType.BootedWithToken:
                case AuthEventType.Refresh: {
                    this.notify(AuthAction.Refreshing);
                    this.$store.dispatch('profile/setIsWaitingForAuth', true);
                    this.authRepository.refresh((_a = event.token) === null || _a === void 0 ? void 0 : _a.accessToken)
                        .then((model) => {
                        const user = model.getUser();
                        this.authService.setUser(user);
                        this.authService.setToken(model.toAuthToken());
                        this.$store.dispatch('profile/setIsUserLoggedIn', true);
                        this.$store.dispatch('profile/setMe', user);
                        if (typeof window !== 'undefined' && typeof document !== 'undefined') {
                            LocalStorage.set('d24-user-id', user.uniqueId);
                            LocalStorage.set('d24-user-com-id', user.userComId);
                            LocalStorage.set('d24-client-id', user.id);
                            LocalStorage.set('d24-client-sub', user.subscriptionType);
                        }
                        this.eventBus.emit('auth:logged-in', user);
                    })
                        .catch((e) => {
                        this.authService.deleteToken();
                        if (typeof window !== 'undefined' && typeof document !== 'undefined') {
                            LocalStorage.remove('d24-user-id');
                            LocalStorage.remove('d24-user-com-id');
                            LocalStorage.remove('d24-client-id');
                            LocalStorage.remove('d24-client-sub');
                        }
                    })
                        .finally(() => {
                        this.notify(AuthAction.Refreshed);
                        this.$store.dispatch('profile/setIsWaitingForAuth', false);
                    });
                    break;
                }
                case AuthEventType.Authenticated:
                case AuthEventType.Invalidated: {
                    this.notify(AuthAction.Booted);
                    this.$store.dispatch('profile/setIsWaitingForAuth', false);
                    this.$store.dispatch('profile/setIsUserLoggedIn', this.authService.check());
                    this.$store.dispatch('profile/setMe', this.authService.user);
                    break;
                }
            }
        });
    }
    /**
     * Emits the specified `app:authentication` event.
     *
     * @param action - Type of the action (notification).
     */
    notify(action) {
        this.eventBus.emit('app:authentication', { action });
    }
    async setUserData() {
        var _a, _b, _c, _d, _e, _f;
        await this.subscriptionService.setSubscriptionData((_b = (_a = this.me) === null || _a === void 0 ? void 0 : _a.subscription) !== null && _b !== void 0 ? _b : null, (_d = (_c = this.me) === null || _c === void 0 ? void 0 : _c.subscriptionType) !== null && _d !== void 0 ? _d : null, (_f = (_e = this.me) === null || _e === void 0 ? void 0 : _e.subscriptionPeriod) !== null && _f !== void 0 ? _f : null);
    }
    async onAuthStateChange() {
        var _a;
        // console.log('Auth state changed')
        await this.setUserData();
        if (!this.isLoggedIn) {
            await this.siteService.setAdsOverridesConfigForUser(this.me, (_a = this.subscriptionService) !== null && _a !== void 0 ? _a : null);
            await this.tryLoadScripts();
        }
        // We don't want popup to appear in Oauth.vue page
        // Temporary off one tap login
        // if (!this.isLoggedIn && this.$route.name !== 'profile.oauth') {
        //   this.initializeGoogleOneTap(this.onCredentialsFromGoogleOneTap)
        // }
    }
    async onUserChange() {
        var _a;
        // console.log('User changed', this.me)
        await this.setUserData();
        await this.siteService.setAdsOverridesConfigForUser(this.me, (_a = this.subscriptionService) !== null && _a !== void 0 ? _a : null);
        await this.tryLoadScripts();
    }
    async tryLoadScripts() {
        if (typeof window === 'undefined' || typeof document === 'undefined') {
            return;
        }
        const adsConfig = this.siteService.getAdsConfig();
        if (adsConfig.ads.wtg) {
            await this.loadScriptWtg();
        }
        if (adsConfig.ads.taboola) {
            await this.loadTaboolaScript();
        }
        else {
            // console.log('Taboola is disabled')
            if (Object.prototype.hasOwnProperty.call(window, '_taboola')) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                // window._taboola.push({ flush: true })
                // console.log('Taboola flush')
                this.shouldReload(this.taboolaLoaded, 'Taboola', adsConfig.ads.taboola);
                // window.location.reload()
            }
        }
    }
    async loadScriptWtg() {
        var _a;
        const adsConfig = this.siteService.getAdsConfig();
        if (!adsConfig.ads.wtg) {
            // console.log('DisableWtg')
            return;
        }
        if (this.shouldReload(this.wtgLoaded, 'WTG', adsConfig.ads.wtg)) {
            return;
        }
        if (this.wtgLoaded) {
            return;
        }
        if (adsConfig.ads.wtg) {
            const link = (_a = adsConfig.wtgLink) !== null && _a !== void 0 ? _a : 'https://lib.wtg-ads.com/lib.min.js';
            if (adsConfig.useNewWTG) {
                // console.info('using new WTG config!')
                window.w2g = window.w2g || {};
                window.w2g.hostname = this.siteService.getActiveSite().domain;
                window.w2g.targeting = window.w2g.targeting || {};
                window.w2g.targeting = {
                    ...window.w2g.targeting,
                    ...this.adTargeting,
                    adReloadAll: adsConfig.ads.adReloadAll ? 'true' : 'false'
                };
                this.eventBus.handle('ui:router.beforeChange', this.clearAdTargeting);
            }
            setTimeout(() => {
                loadScript(link).catch(() => {
                    // console.warn('Failed to load WTG script.')
                });
            }, adsConfig.wtgDelay || 200);
            this.wtgLoaded = true;
        }
    }
    async loadTaboolaScript() {
        const adsConfig = this.siteService.getAdsConfig();
        if (!adsConfig.ads.taboola) {
            // console.log('DisableTaboola')
            return;
        }
        // console.log('LoadTaboolaScript', this.taboolaLoaded, adsConfig.ads.taboola, adsConfig.ads)
        if (this.shouldReload(this.taboolaLoaded, 'Taboola', adsConfig.ads.taboola)) {
            return;
        }
        if (this.taboolaLoaded) {
            return;
        }
        const domainTaboolaScript = {
            [Domain.Defence24]: '//cdn.taboola.com/libtrc/waytogrow-defencegroup-defence24/loader.js',
            [Domain.Defence24COM]: '//cdn.taboola.com/libtrc/waytogrow-defencegroup-defence24com/loader.js',
            [Domain.Energetyka24]: '//cdn.taboola.com/libtrc/waytogrow-defencegroup-energetyka24/loader.js',
            [Domain.CyberDefence24]: '//cdn.taboola.com/libtrc/waytogrow-defencegroup-cyberdefence24/loader.js',
            [Domain.InfoSecurity24]: '//cdn.taboola.com/libtrc/waytogrow-defencegroup-infosecurity24/loader.js',
            [Domain.Space24]: '//cdn.taboola.com/libtrc/waytogrow-defencegroup-space24/loader.js'
        };
        const taboolaScriptUrl = domainTaboolaScript[this.siteService.getActiveSite().domain];
        if (!taboolaScriptUrl) {
            this.taboolaLoaded = true;
            return;
        }
        try {
            // console.info('load Taboola')
            await loadScript(taboolaScriptUrl);
            this.taboolaLoaded = true;
            this.siteService.taboolaInitialLoad = true;
        }
        catch {
            console.warn('Failed to load Taboola script.');
        }
    }
    setAdTargeting(targeting) {
        const adsConfig = this.siteService.getAdsConfig();
        if (adsConfig.useNewWTG) {
            this.adTargeting = targeting;
            if (this.wtgLoaded) {
                window.w2g.targeting = targeting;
            }
        }
    }
    clearAdTargeting() {
        this.setAdTargeting({});
    }
    shouldReload(isLoaded, scriptName, shouldBeLoaded) {
        // console.info('subscribed user: checking...')
        if (isLoaded && !shouldBeLoaded) {
            // console.info('reload becouser ' + scriptName + ' is loaded')
            window.location.reload();
            return true;
        }
        return false;
    }
    async onCredentialsFromGoogleOneTap(response) {
        try {
            const model = await this.authRepository.logIn(new LoginIntention({
                grantType: GrantType.Google,
                token: response.credential
            }));
            const user = model.getUser();
            this.authService.setUser(user);
            this.authService.setToken(model.toAuthToken());
            this.$store.dispatch('profile/setMe', user);
            this.subscriptionService.setSubscriptionData(user.subscription, user.subscriptionType, user.subscriptionPeriod);
            if (typeof window !== 'undefined' && typeof document !== 'undefined') {
                LocalStorage.set('d24-user-id', user.uniqueId);
                LocalStorage.set('d24-user-com-id', model.getUser().userComId);
                LocalStorage.set('d24-client-id', user.id);
                LocalStorage.set('d24-client-sub', user.subscriptionType);
            }
            this.eventBus.emit('auth:logged-in', user);
        }
        catch (e) {
            if (e instanceof ResourceActionFailed) {
                switch (e.status) {
                    // There is a huge possibility that this case will never happen.
                    case ConnectorErrors.Unauthenticated:
                        this.modalConnector.open(EModal.NoAccountModal);
                        break;
                    case ConnectorErrors.Forbidden:
                        localStorage.setItem('oauthProcess', 'register');
                        this.$router.push({
                            name: 'profile.oauth',
                            query: { token: e.payload.token }
                        });
                        break;
                }
                this.$logger(e.message, 'warn');
            }
            this.$logger(e.message, 'warn');
        }
    }
    initializeGoogleOneTap(onCredentials) {
        var _a;
        // @ts-expect-error
        // eslint-disable-next-line no-undef
        const gClient = google || window.google;
        gClient.accounts.id.initialize({
            // eslint-disable-next-line @typescript-eslint/camelcase
            client_id: (_a = process.env.VUE_APP_GOOGLE_CLIENT_ID) !== null && _a !== void 0 ? _a : undefined,
            callback: onCredentials
        });
        // eslint-disable-next-line no-undef
        gClient.accounts.id.prompt((notification) => {
            if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
                document.cookie = 'g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
                gClient.accounts.id.prompt();
            }
        });
    }
};
__decorate([
    Inject(AuthRepositoryType)
], App.prototype, "authRepository", void 0);
__decorate([
    Inject(AuthServiceType)
], App.prototype, "authService", void 0);
__decorate([
    Inject(EventbusType)
], App.prototype, "eventBus", void 0);
__decorate([
    Inject(ModalType)
], App.prototype, "modalConnector", void 0);
__decorate([
    Inject(SiteServiceType)
], App.prototype, "siteService", void 0);
__decorate([
    Inject(SubscriptionsRepositoryType)
], App.prototype, "subscriptions", void 0);
__decorate([
    Inject(SubscriptionServiceType)
], App.prototype, "subscriptionService", void 0);
__decorate([
    Inject(NavigationServiceType)
], App.prototype, "navigation", void 0);
__decorate([
    Watch('site')
], App.prototype, "getAppMetadata", null);
__decorate([
    Watch('isWaitingForAuth')
], App.prototype, "onAuthStateChange", null);
__decorate([
    Watch('me')
], App.prototype, "onUserChange", null);
App = __decorate([
    Component({
        name: 'App',
        components: {
            DefaultLayout,
            ModalWrapper,
            Sidebar
        },
        mixins: [CapturesError],
        beforeMount() {
            this.listenToAuthService();
        },
        beforeDestroy() {
            this.subscription.unsubscribe();
        },
        created() {
            this.getAppMetadata();
        },
        mounted() {
            // this.initHotJar()
        },
        metaInfo() {
            const site = this.siteService.getActiveSite();
            const favicons = site.favicon;
            const meta = {
                link: [
                    {
                        rel: 'icon',
                        type: 'image/png',
                        href: favicons.png || require('@/assets/images/placeholders/favicon.png')
                    },
                    {
                        rel: 'apple-touch-icon',
                        href: favicons.png || require('@/assets/images/placeholders/favicon.png')
                    },
                    {
                        rel: 'icon',
                        type: 'image/svg+xml',
                        href: favicons.svg || require('@/assets/images/placeholders/favicon.svg')
                    },
                    {
                        rel: 'preconnect',
                        href: process.env.VUE_APP_HTTP_D24_API_BASEURL
                    },
                    {
                        rel: 'preload',
                        href: require('@/assets/fonts/icomoon/icomoon.woff'),
                        as: 'font',
                        type: 'font/woff',
                        crossOrigin: 'crossorigin'
                    },
                    {
                        rel: 'preload',
                        href: require('@/assets/fonts/raleway/Raleway-Bold.woff2'),
                        as: 'font',
                        type: 'font/woff2',
                        crossOrigin: 'crossorigin'
                    },
                    {
                        rel: 'preload',
                        href: require('@/assets/fonts/raleway/Raleway-Regular.woff2'),
                        as: 'font',
                        type: 'font/woff2',
                        crossOrigin: 'crossorigin'
                    },
                    {
                        rel: 'preload',
                        href: require('@/assets/fonts/raleway/Raleway-Italic.woff2'),
                        as: 'font',
                        type: 'font/woff2',
                        crossOrigin: 'crossorigin'
                    }
                ],
                titleTemplate: `%s | ${site.name}`,
                meta: this.siteService.getActiveSiteMeta(),
                script: [
                    ...(process.env.VUE_APP_GA_KEY ? [
                        {
                            once: true,
                            vmid: 'gtm-container',
                            async: false,
                            defer: false,
                            innerHTML: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('consent', 'default', {
                'ad_storage': 'denied',
                'ad_personalization': 'denied',
                'ad_user_data': 'denied'
              });
            window.gtag_enable_tcf_support = true;
            dataLayer.push({
              'event': 'info'
            });
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),
          event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'
          ?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
          f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer',
          '${process.env.VUE_APP_GA_KEY}');`,
                            type: 'text/javascript'
                        },
                        {
                            once: true,
                            vmid: 'gam-script',
                            async: true,
                            defer: false,
                            type: 'text/javascript',
                            src: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js'
                        },
                        {
                            once: true,
                            vmid: 'gam-container',
                            async: false,
                            defer: false,
                            type: 'text/javascript',
                            innerHTML: 'window.googletag = window.googletag || { cmd: [] }'
                        }
                        // ,
                        // {
                        //   once: true,
                        //   vmid: 'recsrc-script',
                        //   async: true,
                        //   defer: false,
                        //   type: 'text/javascript',
                        //   src: 'https://fundingchoicesmessages.google.com/i/pub-8288879178472398?ers=1'
                        // },
                        // {
                        //   once: true,
                        //   vmid: 'rec-container',
                        //   async: false,
                        //   defer: false,
                        //   innerHTML: `
                        //     (function() {function signalGooglefcPresent() {
                        //     if (!window.frames['googlefcPresent']) {
                        //     if (document.body) {const iframe = document.createElement('iframe');
                        //     iframe.style = 'width: 0; height: 0; border: none; z-index: -1000; left: -1000px; top: -1000px;';
                        //     iframe.style.display = 'none'; iframe.name = 'googlefcPresent'; document.body.appendChild(iframe);
                        //     } else {setTimeout(signalGooglefcPresent, 0);}}}signalGooglefcPresent();})();
                        //   `,
                        //   type: 'text/javascript'
                        // }
                    ] : [])
                ],
                __dangerouslyDisableSanitizersByTagID: {
                    'gtm-container': ['innerHTML'],
                    'gam-container': ['innerHTML']
                    // ,
                    // 'rec-container': ['innerHTML']
                }
            };
            if (this.theme !== AppTheme.Default) {
                const themeWithDashes = this.theme.replace(/_/g, '-');
                meta.htmlAttrs = { class: `--theme-${themeWithDashes}` };
                const styleProperty = {
                    cssText: require(`@/scss/themes/${themeWithDashes}/index.scss`),
                    type: 'text/css'
                };
                meta.style = meta.style ? [...meta.style, styleProperty] : [styleProperty];
            }
            const sites = this.siteService.getSites();
            if (Array.isArray(sites) && sites.length > 0) {
                const styleProperty = {
                    cssText: `
          :root {
            ${sites.reduce((acc, curr) => {
                        var _a;
                        return (`${acc}\n--site-${curr.id}-theme-color: ${(_a = curr.properties.color) !== null && _a !== void 0 ? _a : 'currentColor'};`);
                    }, '')}
          }
        `,
                    type: 'text/css'
                };
                meta.style = meta.style ? [...meta.style, styleProperty] : [styleProperty];
            }
            return meta;
        }
    })
], App);
export { App };
export default App;
